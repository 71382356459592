import React, { useState } from "react"
import Layout from "../layout/Layout"
import Features from "../components/features/Features"
function FeaturesPage() {
  return (
    <Layout>
      <Features />
    </Layout>
  )
}

export default FeaturesPage
